<template>
  <div>
    <div class="row mb-1">
      <!-- add button -->
      <div class="col-4 pr-0 pl-0 D-flex justify-space-arround">
        <b-button
          block
          variant="primary"
          style="
      justify-content: space-around;
      display: flex;
      border-radius: 0;
      width: max-content;
    "
          :disabled="ActionObj.ApprovedByManagerID != null || ActionObj.IsLegalHold || !onLine"
          @click="addActivity()"
        >
          <span>+ Add Activity</span>
        </b-button>
      </div>
    </div>

    <!-- Table -->
    <b-col class="border-top-dark min-height-10 rounded-sm">
      <b-row
        class="bg-light border-left-dark border-right-dark height-50 d-flex align-items-center"
        :class="{'border-bottom-dark': !Activities.length}"
      >
        <b-col md="4">
          Activity
        </b-col>
        <b-col md="6">
          Assigned
        </b-col>
        <b-col md="1">
          Actions
        </b-col>
      </b-row>
      <b-row
        v-for="(item, index) of Activities"
        :key="index"
        class="border-left-dark border-right-dark d-flex align-items-center"
        :class="{'border-bottom-dark': index + 1 === Activities.length}"
      >
        <b-col>
          <b-row class="pt-1 border-top">
            <b-col md="4">
              <router-link :to="'/cases/view-activity/' + item.ActivityID">
                <span class="text-nowrap">View Activity</span>
              </router-link>
            </b-col>
            <b-col md="6">
              <span>{{ item.Fullname }}</span>
            </b-col>
            <b-col md="1">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <span @click="addClientUpdate(item.ActivityID)">Add Client Update</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <span @click="reassignInvestigator(item.ActivityID)">Reassign Investigator</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!LegalHold">
                    <span @click="deleteActivity(item.ActivityID)">Delete Activity</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-modal
      id="modal-delete-activity"
      ok-only
      ok-variant="danger"
      ok-title="Delete activity"
      modal-class="modal-danger"
      centered
      title="Confirm Delete activity"
      @ok="confirmDeleteActivity()"
    >
      <div v-if="deletedActivity !== null">
        You are about to delete this activity.
        <small>Are you sure you want to do this?</small>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCol, BDropdown, BDropdownItem, BModal, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
  },
  directives: {
    Ripple,
  },
  props: {
    /* eslint-disable */
    ActionID: {
      type: String,
      default: null,
    },
    LegalHold: {
      type: Boolean,
      default: true,
    },
    Activities: {
      type: Array,
      default: () => [],
    },
    ActionObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      deletedActivity: null,
      onLine: navigator.onLine,
    }
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
      getOnlineStatus: "offline/getOnlineStatus",
    }),
  },
  watch: {
    getOnlineStatus(val) {
      this.onLine = val;
    },
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
    addClientUpdate(ActivityID) {
      this.$router.push("/cases/email-case-update/" + ActivityID)
    },
    reassignInvestigator(ActivityID) {
      this.$router.push("/reassign-investigator/" + ActivityID + "/" + this.ActionID)
    },
    deleteActivity(activityID) {
      this.deletedActivity = activityID;
      this.$bvModal.show("modal-delete-activity");
    },
    confirmDeleteActivity() {
      apiService.delete("activity/" + this.deletedActivity)
          .then(res => {
            if (res) {
              this.showToast('success', 'top-center', 4000, 'Activity delete!');
              this.deletedActivity = null;
              this.$emit("deleteActivity")
            }
          })
    },
    addActivity() {
      const UID = localStorage.getItem('UID');
      let canCreate = false;
      if (UID && this.ActionObj.Assignments && this.ActionObj.Assignments.length) {
        this.ActionObj.Assignments.map(item => {
          if (item.UserAccountID === Number(UID)) {
            canCreate = true;
          }
        })
      }
      if (canCreate || this.getCurrentScopes.indexOf('st2.addactivityunconditionally') !== -1) {
        this.$router.push('/cases/add-activity/' + this.ActionID)
      } else {
        this.showToast('danger', 'top-center', 4000, 'Only an assigned investigator can create the activity.');
      }

    }
  }
}
</script>

<style scoped>

</style>